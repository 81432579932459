import {
  genericRouteGuardResult,
  parseUrlStringForAppId,
} from "@/router/routerFuncs"

const routes = [
  {
    path: "/components",
    component: () => import("@apps/components/src/App.vue"),
    children: [
      {
        path: "",
        name: "components.home",
        meta: { title: "Component Library" },
        component: () => import("@apps/components/src/pages/Home.vue"),
      },
      {
        path: "inputs",
        name: "components.inputs",
        meta: { title: "Inputs" },
        component: () => import("@apps/components/src/pages/Inputs.vue"),
      },
      {
        path: "dropdowns",
        name: "components.dropdowns",
        meta: { title: "Dropdowns" },
        component: () => import("@apps/components/src/pages/DropDowns.vue"),
      },
      {
        path: "buttons",
        name: "components.buttons",
        meta: { title: "Buttons" },
        component: () => import("@apps/components/src/pages/Buttons.vue"),
      },
      {
        path: "layouts",
        name: "components.layouts",
        meta: { title: "Layouts" },
        component: () => import("@apps/components/src/pages/Layouts.vue"),
      },
      {
        path: "tables",
        name: "components.tables",
        meta: { title: "Tables" },
        component: () => import("@apps/components/src/pages/Tables.vue"),
      },
      {
        path: "cards",
        name: "components.cards",
        meta: { title: "Cards" },
        component: () => import("@apps/components/src/pages/Cards.vue"),
      },
      {
        path: "base-components",
        name: "components.base-components",
        meta: { title: "Base Components" },
        component: () =>
          import("@apps/components/src/pages/BaseComponents.vue"),
      },
      {
        path: "design-overview",
        meta: { title: "Design Overview" },
        children: [
          {
            path: "design-system",
            name: "components.design-overview.design-system",
            meta: { title: "Design System" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/DesignSystem.vue"
              ),
          },
          {
            path: "accordion",
            name: "components.design-overview.accordion",
            meta: { title: "Accordion" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/AccordionWrapper.vue"
              ),
          },
          {
            path: "button",
            name: "components.design-overview.button",
            meta: { title: "Button" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/ButtonWrapper.vue"
              ),
          },
          {
            path: "checkbox",
            name: "components.design-overview.checkbox",
            meta: { title: "Checkbox" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/CheckboxWrapper.vue"
              ),
          },
          {
            path: "chip",
            name: "components.design-overview.chip",
            meta: { title: "Chip" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/ChipWrapper.vue"
              ),
          },
          {
            path: "divider",
            name: "components.design-overview.divider",
            meta: { title: "Divider" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/DividerWrapper.vue"
              ),
          },
          {
            path: "dropdown",
            name: "components.design-overview.dropdown",
            meta: { title: "Dropdown" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/DropdownWrapper.vue"
              ),
          },
          {
            path: "helpers",
            name: "components.design-overview.helpers",
            meta: { title: "Helpers" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/HelpersWrapper.vue"
              ),
          },
          {
            path: "icons",
            name: "components.design-overview.icons",
            meta: { title: "Icons" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/IconsWrapper.vue"
              ),
          },
          {
            path: "list",
            name: "components.design-overview.list",
            meta: { title: "List" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/ListWrapper.vue"
              ),
          },
          {
            path: "radiobutton",
            name: "components.design-overview.radiobutton",
            meta: { title: "RadioButton" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/RadioButtonWrapper.vue"
              ),
          },
          {
            path: "textarea",
            name: "components.design-overview.textarea",
            meta: { title: "TextArea" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/TextAreaWrapper.vue"
              ),
          },
          {
            path: "textfield",
            name: "components.design-overview.textfield",
            meta: { title: "TextField" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/TextFieldWrapper.vue"
              ),
          },
          {
            path: "toggle",
            name: "components.design-overview.toggle",
            meta: { title: "Toggle" },
            component: () =>
              import(
                "@apps/components/src/pages/DesignOverview/ToggleWrapper.vue"
              ),
          },
        ],
      },
    ],
  },
]

const menuIndex = 1

const menuItems = [
  {
    icon: "Calendar",
    pageName: "components",
    title: "Component Library",
    subMenu: [
      {
        icon: "Calendar",
        pageName: "components.home",
        title: "Home",
      },
      {
        icon: "Pencil",
        // pageName: "components.design-system",
        title: "Design Overview",
        subMenu: [
          {
            icon: "Pencil",
            pageName: "components.design-overview.design-system",
            title: "Design System",
          },
          {
            icon: "ListChecks",
            pageName: "components.design-overview.accordion",
            title: "Accordion",
          },
          {
            icon: "MousePointer2",
            pageName: "components.design-overview.button",
            title: "Button",
          },
          {
            icon: "Check",
            pageName: "components.design-overview.checkbox",
            title: "Checkbox",
          },
          {
            icon: "CreditCard",
            pageName: "components.design-overview.chip",
            title: "Chip",
          },
          {
            icon: "Slash",
            pageName: "components.design-overview.divider",
            title: "Divider",
          },
          {
            icon: "ListChecks",
            pageName: "components.design-overview.dropdown",
            title: "Dropdown",
          },
          {
            icon: "Info",
            pageName: "components.design-overview.helpers",
            title: "Helpers",
          },
          {
            icon: "CreditCard",
            pageName: "components.design-overview.icons",
            title: "Icons",
          },
          {
            icon: "List",
            pageName: "components.design-overview.list",
            title: "List",
          },
          {
            icon: "CreditCard",
            pageName: "components.design-overview.radiobutton",
            title: "Radio Button",
          },
          {
            icon: "TextCursorInput",
            pageName: "components.design-overview.textarea",
            title: "Text Area",
          },
          {
            icon: "TextCursorInput",
            pageName: "components.design-overview.textfield",
            title: "Text Field",
          },
          {
            icon: "ToggleLeft",
            pageName: "components.design-overview.toggle",
            title: "Toggle",
          },
        ],
      },
      {
        icon: "TextCursorInput",
        pageName: "components.inputs",
        title: "Inputs",
      },
      {
        icon: "ListChecks",
        pageName: "components.dropdowns",
        title: "Dropdowns",
      },
      {
        icon: "MousePointer2",
        pageName: "components.buttons",
        title: "Buttons",
      },
      {
        icon: "LayoutTemplate",
        pageName: "components.layouts",
        title: "Layouts",
      },
      {
        icon: "Table",
        pageName: "components.tables",
        title: "Tables",
      },
      {
        icon: "CreditCard",
        pageName: "components.cards",
        title: "Cards",
      },
      {
        icon: "Baseline",
        pageName: "components.base-components",
        title: "Base Components",
      },
    ],
  },
]

export const submoduleRouteGuard = (router: any) => {
  return async (to: any, from: any, next: any) => {
    // step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
    let guardRequirements = {
      appId: "components",
      baseRoles: ["components-admin"],
    }
    let parsedAppID: string | null = parseUrlStringForAppId(to.path)
    if (parsedAppID === "components") {
      // step 2 - inside of this submodule - check if the role is allowed/valid
      const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
      if (routeGuardAllowed) {
        next()
      } else {
        // abort navigation
        next("/") // Redirect to home page - show no access message
      }
    } else {
      next() // Allow other submodule routes and routes
    }
    if (to.path !== from.path) {
      window.scrollTo(0, 0) // Scroll to top when the route changes (ignoring hash/params)
    }
  }
}

export default {
  routes,
  menuIndex,
  menuItems,
}
