import { parseUrlStringForAppId } from "@/router/routerFuncs";
import { genericRouteGuardResult } from "@/router/routerFuncs";
import { useSecurityStore } from "@/stores/security";

const routes = [
	{
		path: "/user-management",
		component: () => import("@apps/user-management/src/App.vue"),
		children: [
			{
				path: "",
				name: "userMgmt.home",
				meta: { title: "User Management" },
				component: () => import("@apps/user-management/src/pages/Home.vue"),
				// children: [
				// 	{
				// 		path: "/userDetails/:id", // Relative path (remove the leading '/')
				// 		name: "userMgmt.userDetails",
				// 		meta: { title: "User Details" },
				// 		component: () =>
				// 			import("@apps/user-management/src/pages/UserDetails.vue"),
				// 	},
				// ],
			},
			{
				path: "/userDetails/:id", // Relative path (remove the leading '/')
				name: "userMgmt.userDetails",
				meta: { title: "User Details" },
				component: () =>
					import("@apps/user-management/src/pages/UserDetails.vue"),
			},
		],
	},
];

const menuIndex = 1;
const menuItems = [
	{
		icon: "Users",
		pageName: "userMgmt.home",
		title: "User Management",
	},
];

// submoduleRouteGuard
// loggedIn handled by main router
export const submoduleRouteGuard = (router: any) => {
	return async (to: any, from: any, next: any) => {
		// step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
		let guardRequirements = {
			appId: "user-management",
			baseRoles: ["usermgmt-admin"],
		};
		let parsedAppID: string | null = parseUrlStringForAppId(to.path);
		if (parsedAppID === guardRequirements.appId) {
			// step 2 - inside of this submodule - check if the role is allowed/valid
			const routeGuardAllowed = await genericRouteGuardResult(
				guardRequirements
			);
			if (routeGuardAllowed) {
				next();
			} else {
				// abort navigation
				next("/"); // Redirect to home page - show no access message
			}
		} else {
			next(); // Allow other submodule routes and routes
		}
	};
};
export default {
	routes,
	menuIndex,
	menuItems,
};
