const gitUrl =
  "https://submodules-read-token:glpat-g6Cjsa2yRLtQAkP2wiaQ@tmaeurgit1.i.telenormaritime.com/star-fleet/submodules"

const submodules = [
  {
    module: "wifipkgmgmnt",
    path: `${gitUrl}/wifi/wifiPkgMgmnt.git`,
    tag: "v0.0.16",
  },
  {
    module: "uhsman",
    path: `${gitUrl}/uhs/uhsman-sub.git`,
    tag: "v1.0.6",
  },
  {
    module: "mwb1",
    path: `${gitUrl}/data-analysis/mwb1.git`,
    tag: "v0.0.1",
  },
  {
    module: "sim",
    path: `${gitUrl}/sim-esim/sim-v2.git`,
    tag: "v1.1.0",
  },
  {
    module: "datagui",
    path: `${gitUrl}/uhs/data-gui.git`,
    tag: "v1.0.1",
  },
  {
    module: "components",
    path: `${gitUrl}/components`,
    tag: "v1.2.15",
  },
  {
    module: "private5g",
    path: `${gitUrl}/5g/private-5g.git`,
    tag: "v1.0.5",
  },
  {
    module: "sim-pipe",
    path: `${gitUrl}/sim-esim/sim-pipeline.git`,
    tag: "v0.0.1",
  },
  {
    module: "user-management",
    path: `${gitUrl}/usermgmt.git`,
    tag: "v1.0.5",
  },
  {
		module: "topic-library",
		path: `${gitUrl}/uds/topic-library.git`,
    tag:"v1.0.1"
	},
]

export default submodules
