import wifipkgmgmntRouter from '../../apps/wifipkgmgmnt/src/router.ts';
import uhsmanRouter from '../../apps/uhsman/src/router.ts';
import simRouter from '../../apps/sim/src/router.ts';
import dataguiRouter from '../../apps/datagui/src/router.ts';
import componentsRouter from '../../apps/components/src/router.ts';
import private5gRouter from '../../apps/private5g/src/router.ts';
import simPipeRouter from '../../apps/sim-pipe/src/router.ts';
import userManagementRouter from '../../apps/user-management/src/router.ts';
import topicLibraryRouter from '../../apps/topic-library/src/router.ts';

export const submoduleRoutes = [wifipkgmgmntRouter.routes, uhsmanRouter.routes, simRouter.routes, dataguiRouter.routes, componentsRouter.routes, private5gRouter.routes, simPipeRouter.routes, userManagementRouter.routes, topicLibraryRouter.routes].flat();

import { submoduleRouteGuard as wifipkgmgmntGuard } from '../../apps/wifipkgmgmnt/src/router.ts';
import { submoduleRouteGuard as uhsmanGuard } from '../../apps/uhsman/src/router.ts';
import { submoduleRouteGuard as simGuard } from '../../apps/sim/src/router.ts';
import { submoduleRouteGuard as dataguiGuard } from '../../apps/datagui/src/router.ts';
import { submoduleRouteGuard as componentsGuard } from '../../apps/components/src/router.ts';
import { submoduleRouteGuard as private5gGuard } from '../../apps/private5g/src/router.ts';
import { submoduleRouteGuard as simPipeGuard } from '../../apps/sim-pipe/src/router.ts';
import { submoduleRouteGuard as userManagementGuard } from '../../apps/user-management/src/router.ts';
import { submoduleRouteGuard as topicLibraryGuard } from '../../apps/topic-library/src/router.ts';

export const submoduleGuards = {wifipkgmgmntGuard, uhsmanGuard, simGuard, dataguiGuard, componentsGuard, private5gGuard, simPipeGuard, userManagementGuard, topicLibraryGuard};

export const submoduleMenuInfo = {"wifipkgmgmnt": {
      menuItems: wifipkgmgmntRouter.menuItems,
      menuIndex: wifipkgmgmntRouter.menuIndex,
    },
  "uhsman": {
      menuItems: uhsmanRouter.menuItems,
      menuIndex: uhsmanRouter.menuIndex,
    },
  "sim": {
      menuItems: simRouter.menuItems,
      menuIndex: simRouter.menuIndex,
    },
  "datagui": {
      menuItems: dataguiRouter.menuItems,
      menuIndex: dataguiRouter.menuIndex,
    },
  "components": {
      menuItems: componentsRouter.menuItems,
      menuIndex: componentsRouter.menuIndex,
    },
  "private5g": {
      menuItems: private5gRouter.menuItems,
      menuIndex: private5gRouter.menuIndex,
    },
  "sim-pipe": {
      menuItems: simPipeRouter.menuItems,
      menuIndex: simPipeRouter.menuIndex,
    },
  "user-management": {
      menuItems: userManagementRouter.menuItems,
      menuIndex: userManagementRouter.menuIndex,
    },
  "topic-library": {
      menuItems: topicLibraryRouter.menuItems,
      menuIndex: topicLibraryRouter.menuIndex,
    }};