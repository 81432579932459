import { parseUrlStringForAppId } from "@/router/routerFuncs"
import { genericRouteGuardResult } from "@/router/routerFuncs"

const routes = [
	{
		path: "/uds/topic-library",
		component: () => import("@apps/topic-library/src/App.vue"),
		children: [
			{
				path: "",
				name: "topicLibrary.home",
				meta: { title: "Topic Library" },
				component: () => import("@apps/topic-library/src/pages/Home.vue"),
			},
		],
	},
]

const menuIndex = 1
const menuItems = [
	{
		icon: "Activity",
		pageName: "topicLibrary.home",
		title: "Data Management",
		parent: "uds",
	},
]

export const submoduleRouteGuard = (router: any) => {
	return async (to: any, from: any, next: any) => {
		// step 1  - catch all submodule route events - must allow navigation with next() call if not this submodule
		let guardRequirements = {
			appId: "topic-library",
			baseRoles: ["topic-library-admin"],
		}
		// let guardRequirements = {
		// 	appId: "uds-th",
		// 	baseRoles: ["uds-admin", "uds-basic", "uds-tm-admin", "uds-tm-basic"],
		// }

		let parsedAppID: string | null = parseUrlStringForAppId(to.path, true)
		console.log("to.path", to.path)
		console.log("parsedAppID", parsedAppID)
		console.log("guardRequirements.appId", guardRequirements.appId)
		// parsedAppID = parsedAppID + "-th"
		if (parsedAppID === guardRequirements.appId) {
			// step 2 - inside of this submodule - check if the role is allowed/valid
			console.log("guardRequirements", guardRequirements)
			const routeGuardAllowed = await genericRouteGuardResult(guardRequirements)
			console.log("routeGuardAllowed", routeGuardAllowed)
			if (routeGuardAllowed) {
				next()
			} else {
				// abort navigation
				next("/") // Redirect to home page - show no access message
			}
		} else {
			next() // Allow other submodule routes and routes
		}
	}
}
export default {
	routes,
	menuIndex,
	menuItems,
}
