<script setup lang="ts">
import "@/assets/css/themes/custom/side-nav.css"
import { useRoute, useRouter } from "vue-router"
import logoUrl from "@/assets/images/tm_logo_white.svg"
import logoUrlBlack from "@/assets/images/tm_logo_black.svg"
import smallLogoUrl from "@/assets/images/tm_logo_small.svg"
import Tippy from "@/components/Base/Tippy"
import Lucide from "@/components/Base/Lucide"
import CustomTopBar from "@/components/Custom/Theme/TopBar.vue"
import { useMenuStore } from "@/stores/menu"
import {
  type ProvideForceActiveMenu,
  forceActiveMenu,
  type Route,
  type FormattedMenu,
  nestedMenu,
  linkTo,
  // enter,
  // leave,
} from "./side-menu"
import {
  watch,
  reactive,
  ref,
  computed,
  onMounted,
  provide,
  onUnmounted,
  onBeforeMount,
} from "vue"
import { useThemeStore } from "@/stores/theme"
import Footer from "./Footer.vue"
import { useDarkModeStore } from "@/stores/dark-mode"

const darkModeStore = useDarkModeStore()
const themeStore = useThemeStore()
const theme = computed(() => themeStore.themeValue.name)

onMounted(() => {
  console.log("mounted menu", menu.value)
})

const route: Route = useRoute()
const router = useRouter()
let formattedMenu = reactive<Array<FormattedMenu | "divider">>([])
const setFormattedMenu = (
  computedFormattedMenu: Array<FormattedMenu | "divider">
) => {
  Object.assign(formattedMenu, computedFormattedMenu)
}
const menuStore = useMenuStore()
const menu = computed(() => nestedMenu(menuStore.menu("side-menu"), route))
const windowWidth = ref(window.innerWidth)

provide<ProvideForceActiveMenu>("forceActiveMenu", (pageName: string) => {
  forceActiveMenu(route, pageName)
  setFormattedMenu(menu.value)
})

watch(menu, () => {
  console.log("menu changed side menu", menu.value)
  setFormattedMenu(menu.value)
})

watch(
  computed(() => route.path),
  () => {
    delete route.forceActiveMenu
  }
)

onMounted(() => {
  setFormattedMenu(menu.value)

  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth
  })
})

//Sidebar open/close
const logo = ref(logoUrlBlack)
const isSidebarOpen = ref(true)
const toggleSidebarSize = () => {
  isSidebarOpen.value = !isSidebarOpen.value
}
watch(isSidebarOpen, (newValue) => {
  if (newValue) {
    isDarkMode()
  } else {
    setTimeout(() => {
      logo.value = smallLogoUrl
    }, 200)
  }
})

watch(
  () => darkModeStore.darkModeValue,
  () => {
    if (isSidebarOpen.value) {
      isDarkMode()
    }
  }
)

const isDarkMode = () => {
  if (darkModeStore.darkModeValue) {
    logo.value = logoUrl
  } else {
    logo.value = logoUrlBlack
  }
}

onBeforeMount(() => {
  if (window.screen.width <= 1024) {
    isSidebarOpen.value = false
  }
  if (isSidebarOpen.value) {
    isDarkMode()
  } else {
    logo.value = smallLogoUrl
  }
})
const getClasses = () => {
  if (themeStore.themeValue.name === "tinker") {
    return [
      ,// 'after:absolute after:inset-y-0 after:left-0 after:z-[-1] after:-ml-4 after:mt-8 after:hidden after:w-full after:rounded-[40px_0px_0px_0px] after:bg-white/10 after:content-[""] after:dark:bg-darkmode-400/50 md:after:block',
    ].join(" ")
  } else {
    return 'md:max-w-auto min-h-screen min-w-0 max-w-full flex-1 rounded-[30px] bg-slate-100 px-4 pb-10 before:block before:h-px before:w-full before:content-[""] dark:bg-darkmode-700 md:px-[22px]'
  }
}

//Active link issue when path is not a navlink in the navigation
const flattenMenu = (menu: any[]) => {
  let flatMenu: any[] = []
  menu.forEach((item: { subMenu: any[]; pageName: any }) => {
    flatMenu.push(item)
    if (item.subMenu) {
      flatMenu = flatMenu.concat(
        item.subMenu.map((subItem: any) => ({
          ...subItem,
          parentPageName: item.pageName,
        }))
      )
    }
  })
  return flatMenu
}
const activeLink = computed(() => {
  const pathSegments = route.path.split("/").filter(Boolean)
  let activePath = ""
  const flatMenu = flattenMenu(formattedMenu)

  for (let i = pathSegments.length; i > 0; i--) {
    const pathToCheck = pathSegments.slice(0, i).join(".")
    const menuItemExists = flatMenu.some(
      (menuItem) => menuItem.pageName === pathToCheck
    )

    if (menuItemExists) {
      activePath = pathToCheck
      break
    }
  }

  return activePath
})

const isActiveMenuItem = (pageName: string): boolean => {
  // Direct match
  if (activeLink.value === pageName) return true

  // Check if any child matches
  const flatMenu: any = flattenMenu(formattedMenu)
  const activeItem = flatMenu.find(
    (item: any) => item.pageName === activeLink.value
  )
  if (activeItem && activeItem.parentPageName) {
    return activeItem.parentPageName === pageName
  }

  return false
}

//Making sure the dropdown stays open if you visit a site thats nested under the dropdown
watch(
  [activeLink],
  () => {
    // Loop through all menu items to update their 'activeDropdown' property
    formattedMenu.forEach((menuItem) => {
      if (menuItem?.subMenu) {
        // Check if any submenu item is active
        const isAnySubmenuActive = menuItem.subMenu.some(
          (subMenuItem: { pageName: string }) =>
            isActiveMenuItem(subMenuItem.pageName)
        )

        // Automatically open the dropdown if a submenu item is active
        menuItem.activeDropdown = isAnySubmenuActive
      }
    })
  },
  { immediate: true, deep: true }
)

// Animation functions
const beforeEnter = (el: HTMLElement) => {
  el.style.height = "0"
}

const enter = (el: HTMLElement) => {
  el.style.height = el.scrollHeight + "px"
  el.addEventListener(
    "transitionend",
    () => {
      el.style.height = "auto"
    },
    { once: true }
  )
}

const leave = (el: HTMLElement) => {
  el.style.height =
    Number(el.scrollHeight) < 384 ? el.scrollHeight + "px" : "384px"
  setTimeout(() => {
    el.style.height = "0"
  })
}
// Animation functions end
</script>

<template>
  <div class="relative flex justify-start md:px-0 md:py-0">
    <!--     <MobileMenu /> -->

    <!-- BEGIN: Side Menu -->
    <div
      class="hidden overflow-x-hidden border-r border-gray-300 bg-gray-100 transition-[width] duration-300 dark:bg-darkmode-800 md:block"
      :class="isSidebarOpen ? 'w-[260px]' : !isSidebarOpen ? 'w-[105px]' : ''"
    >
      <nav
        class="custom-scrollbar fixed z-10 hidden h-screen overflow-y-auto overflow-x-hidden px-5 pb-16 duration-300 ease-in-out md:block"
        :class="isSidebarOpen ? 'w-[260px]' : !isSidebarOpen ? 'w-[105px]' : ''"
      >
        <!-- <div class="before:absolute before:top-0 before:left-0 before:right-0 before:h-10 before:bg-gradient-to-t before:from-white before:to-transparent ></div> -->
        <div
          class="fixed bottom-0 z-50 -ml-5 hidden justify-end border-t border-gray-300 bg-gray-100 from-10% to-70% px-5 pb-6 pt-4 transition-[width] duration-300 before:absolute before:-top-10 before:left-0 before:right-0 before:h-10 before:bg-gradient-to-t before:from-gray-100 before:to-transparent before:transition-all dark:bg-darkmode-800 dark:before:from-darkmode-800 md:flex"
          :class="
            isSidebarOpen ? 'w-[250px]' : !isSidebarOpen ? 'w-[95px]' : ''
          "
        >
          <div class="flex items-center">
            <Tippy
              :key="isSidebarOpen"
              :content="isSidebarOpen ? 'Collapse sidemenu' : 'Expand sidemenu'"
              :options="{
                placement: 'right',
              }"
            >
              <div
                class="flex h-6 w-6 cursor-pointer items-center justify-center rounded-full bg-gray-200 dark:bg-darkmode-400"
                @click="toggleSidebarSize"
              >
                <Lucide
                  class="h-5 text-black dark:text-white"
                  :class="isSidebarOpen ? 'rotate-90' : '-rotate-90'"
                  icon="ChevronDown"
                />
              </div>
            </Tippy>
          </div>
        </div>

        <div class="flex items-center justify-center">
          <RouterLink
            :to="{ name: 'homepage' }"
            class="intro-x mt-3 flex items-center pt-4"
          >
            <img
              alt="Telenor Maritime Dashboard"
              :class="isSidebarOpen ? 'w-64' : ' h-10'"
              :src="logo"
            />
          </RouterLink>
        </div>
        <div
          class="my-6 border-b border-gray-400 dark:border-darkmode-400"
        ></div>

        <ul>
          <template v-for="(menu, menuKey) in formattedMenu">
            <div
              v-if="menu == 'divider'"
              class="my-6 border-b border-black dark:border-darkmode-400"
              :key="'divider-' + menuKey"
            ></div>
            <li v-else :key="menuKey">
              <Tippy
                as="a"
                :content="menu.title"
                :options="{
                  placement: 'right',
                }"
                :disable="isSidebarOpen ? true : false"
                :href="
                  menu.subMenu
                    ? '#'
                    : ((pageName: string | undefined) => {
                        try {
                          return router.resolve({
                            name: pageName,
                          }).fullPath;
                        } catch (err) {
                          return '';
                        }
                      })(menu.pageName)
                "
                @click="(event: MouseEvent) => {
                  event.preventDefault();
                  linkTo(menu, router);
                  setFormattedMenu([...formattedMenu]);
                }"
                :class="[
                  menu.active ? 'bg-gray-200 dark:bg-darkmode-600' : '',
                  menu.activeDropdown ? 'bg-gray-200 dark:bg-darkmode-600' : '',
                  isActiveMenuItem(menu.pageName)
                    ? 'bg-gray-200 dark:bg-darkmode-600'
                    : '',
                ]"
                class="my-1 flex h-14 w-full items-center rounded-lg pl-5 text-black duration-200 hover:bg-gray-200 dark:text-white dark:hover:bg-darkmode-600"
              >
                <div>
                  <Lucide class="h-5" :icon="menu.icon" />
                </div>
                <div class="flex w-full justify-between">
                  <p class="pl-2 text-sm" v-if="isSidebarOpen">
                    {{ menu.title }}
                  </p>
                  <div v-if="menu.subMenu" :class="isSidebarOpen ? 'pr-3' : ''">
                    <Lucide
                      :class="[
                        menu.activeDropdown ? 'rotate-180 transform' : '',
                      ]"
                      class="h-4 duration-500"
                      icon="ChevronDown"
                    />
                  </div>
                </div>
              </Tippy>
              <Transition
                @enter="enter"
                @leave="leave"
                @before-enter="beforeEnter"
                enter-active-class="duration-500 ease-in-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="duration-500 ease-in-out"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
              >
                <ul
                  v-if="
                    (menu.subMenu && menu.activeDropdown) ||
                    (menu.subMenu &&
                      menu.subMenu.some(
                        (sub) => sub.activeDropdown && sub.active && sub.subMenu
                      ))
                  "
                  class="overflow-hidden rounded-lg bg-gray-200 dark:bg-darkmode-700"
                >
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                    class="intro-x"
                  >
                    <Tippy
                      as="a"
                      :content="subMenu.title"
                      :options="{
                        placement: 'right',
                      }"
                      :disable="isSidebarOpen ? true : false"
                      :href="
                        subMenu.subMenu
                          ? '#'
                          : ((pageName: string | undefined) => {
                              try {
                                return router.resolve({
                                  name: pageName,
                                }).fullPath;
                              } catch (err) {
                                return '';
                              }
                            })(subMenu.pageName)
                      "
                      :class="[
                        subMenu.active
                          ? 'text-black dark:text-white'
                          : 'text-black/60 dark:text-white/60',
                        isActiveMenuItem(subMenu.pageName)
                          ? 'text-black dark:text-white'
                          : 'text-black/60 dark:text-white/60',
                      ]"
                      @click="(event: MouseEvent) => {
                        event.preventDefault();
                        linkTo(subMenu, router);
                        setFormattedMenu([...formattedMenu]);
                      }"
                      class="flex h-14 w-full items-center rounded-lg pl-5"
                    >
                      <div>
                        <Lucide :icon="subMenu.icon" />
                      </div>
                      <div class="flex w-full justify-between">
                        <p class="pl-2 text-sm" v-if="isSidebarOpen">
                          {{ subMenu.title }}
                        </p>
                        <div
                          v-if="subMenu.subMenu"
                          :class="isSidebarOpen ? 'pr-2' : ''"
                        >
                          <Lucide
                            :class="subMenu.activeDropdown ? ' rotate-180' : ''"
                            class="h-4 duration-500"
                            icon="ChevronDown"
                          />
                        </div>
                      </div>
                    </Tippy>
                    <Transition
                      @enter="enter"
                      @leave="leave"
                      @before-enter="beforeEnter"
                      enter-active-class="duration-500 ease-in-out"
                      enter-from-class="opacity-0"
                      enter-to-class="opacity-100"
                      leave-active-class="duration-500 ease-in-out"
                      leave-from-class="opacity-100"
                      leave-to-class="opacity-0"
                      v-if="subMenu.subMenu"
                    >
                      <ul
                        v-if="subMenu.subMenu && subMenu.activeDropdown"
                        class="mx-2 overflow-hidden rounded-lg bg-gray-300 dark:bg-darkmode-600"
                      >
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                          class="intro-x"
                        >
                          <Tippy
                            as="a"
                            :content="lastSubMenu.title"
                            :options="{
                              placement: 'right',
                            }"
                            :disable="isSidebarOpen ? true : false"
                            :href="
                              lastSubMenu.subMenu
                                ? '#'
                                : ((pageName: string | undefined) => {
                                    try {
                                      return router.resolve({
                                        name: pageName,
                                      }).fullPath;
                                    } catch (err) {
                                      return '';
                                    }
                                  })(lastSubMenu.pageName)
                            "
                            :class="[
                              lastSubMenu.active
                                ? 'text-black dark:text-white'
                                : 'text-black/60 dark:text-white/60',
                            ]"
                            @click="(event: MouseEvent) => {
                              event.preventDefault();
                              linkTo(lastSubMenu, router);
                              setFormattedMenu([...formattedMenu]);
                            }"
                            class="flex h-14 w-full items-center rounded-lg px-3"
                          >
                            <div class="h-5 pr-2">
                              <Lucide :icon="lastSubMenu.icon" />
                            </div>
                            <div class="text-sm">
                              <p v-if="isSidebarOpen">
                                {{ lastSubMenu.title }}
                              </p>
                            </div>
                          </Tippy>
                        </li>
                      </ul>
                    </Transition>
                  </li>
                </ul>
              </Transition>
            </li>
          </template>
        </ul>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
    </div>
    <div
      class="relative min-h-screen min-w-0 max-w-full flex-1 bg-gray-100 pt-[71px] dark:bg-darkmode-700 md:max-w-none md:rounded-[25px/40px_0px_0px_0px] md:pt-0"
    >
      <div class="min-h-screen w-full bg-white dark:bg-darkmode-700">
        <CustomTopBar />
        <RouterView />
      </div>
      <Footer />
    </div>
    <!-- END: Content -->
  </div>
</template>

<style scoped>
.custom-scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.custom-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>
